.Counselling__Print__Page {
    background: #fff;
}

header.main-header {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    color: #fff;
    background-color: $branding-color-primary;
    height: 80px;

    @media (min-width: $screen-sm-min) {
        position: fixed;
        color: $text-color;
        background-color: #fff;
        margin-bottom: 20px;
    }

    > .container-fluid {
        padding-left: 0;
        padding-right: 0;

        > .navbar-collapse {
            margin-left: 55px;
        }
    }

    .Counselling__Print__Page & {
        display: none;
    }

    .navbar-nav {
        > li {
            > a {
                color: #fff;

                @media (min-width: $screen-sm-min) {
                    color: $text-color;
                }

                &:hover,
                &:active,
                &:focus {
                    color: #fff;

                    @media (min-width: $screen-sm-min) {
                        color: $text-color;
                    }
                }
            }
        }
    }

    .navbar-header {
        padding: 0 10px 0 0;

        .navbar-toggle {
            padding: 6px 10px;
            border: 1px solid rgba(0, 0, 0, .15);
            box-shadow: 1px 1px 0 rgba(255, 255, 255, .2) inset;
            outline: none;

            &:hover {
                background-color: rgba(255, 255, 255, .07);
            }

            &:focus {
                background-color: transparent;
            }

            &:active {
                box-shadow: 1px 1px 1px rgba(0, 0, 0, .07) inset;
            }

            .fa {
                color: #fff;
                font-size: 18px;
            }
        }

        .navbar-brand {
            margin-left: 10px;
            text-align: left;
            color: #fff;
            font-size: 19px;
            line-height: 18px;
            font-weight: 300;
            background-color: $branding-color-primary;

            @media (min-width: $screen-sm-min) {
                width: 64px;
                margin-left: -24px;
            }

            span {
                font-weight: 600;
            }

            i {
                font-style: normal;

                @media (min-width: $screen-sm-min) {
                    display: none;
                }
            }
        }
    }

    .navbar-collapse {
        position: relative;
        padding: 0;
        max-height: none;
        border-color: darken($branding-color-primary, 10%);

        .navbar-nav {
            margin: 0;
            padding: 0 10px;

            .dropdown-menu {
                border-top: 0;
            }

            .open {
                > a,
                > a:hover,
                > a:focus {
                    color: #fff;
                    background-color: rgba(255, 255, 255, .1);

                    @media (min-width: $screen-sm-min) {
                        color: $text-color;
                        background-color: rgba(0, 0, 0, .1);
                    }
                }

                .dropdown-menu {
                    > li > a {
                        color: #fff;

                        @media (min-width: $screen-sm-min) {
                            color: $text-color;
                        }
                    }
                }
            }
        }

        .dropdown {
            > a {
                transition: background-color 0.05s ease;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }

        .profile-menu {
            margin: 0;
            cursor: pointer;
        }
    }
}

//--------------------------------------------------

.main-content {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 0;

    @media (min-width: $screen-sm-min) {
        display: table;
        padding-top: $navbar-height;
    }

    .Counselling__Print__Page & {
        padding-top: 0;
    }
}

//--------------------------------------------------

.Page_Navigation {
    position: fixed;
    width: 54px;
    height: 100%;
    left: 0;
    top: 0;
    background: $vbst-nav-bgcolor;
    z-index: 5000;
    color: #fff;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-property: width;
    overflow: hidden;

    &--opened,
    &:hover {
        width: $vbst-nav-opened-width;
    }

    &--opened {
        transition-duration: 0;
        overflow: visible;
    }

    .Counselling__Print__Page & {
        display: none;
    }

    &-Vendor {
        border-bottom: 1px solid $vbst-nav-border-color;
        height: 80px;
        overflow: hidden;
        width: $vbst-nav-opened-width;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-Vendor-Brand {
        float: left;
        width: 54px;
        height: 70px;
        background-image: url($vbst-nav-brand-logo-src);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $vbst-nav-brand-logo-width $vbst-nav-brand-logo-height;
        margin-right: 10px;

        @media #{$retina} {
            background-image: url(retinize($vbst-nav-brand-logo-src));
        }

        > a {
            display: block;
            width: 100%;
            height: 100%;
            text-indent: -9999px;
            overflow: hidden;
        }
    }

    &-Vendor-Information {
        margin-top: 16px;
    }

    &-Vendor-Information-Version,
    &-Vendor-Information-Timestamp {
        font-weight: bold;
        display: block;
    }

    &-Vendor-Information-Timestamp {
        font-size: 0.786em;
        font-weight: normal;
        font-style: italic;
    }

    &-Menu {
        position: absolute;
        display: table;
        top: 0;
        height: 100%;
        margin: 0;
        padding: 90px 0 0 0;
        list-style: none;
        width: $vbst-nav-opened-width;
        z-index: -1;

        > li {
            &:last-child {
                display: table-footer-group;
            }
        }

        li {
            margin: 10px 0;

            > a {
                color: $vbst-nav-text-color;
                display: table;
                width: 100%;

                > .Page_Navigation-Icon,
                > .Page_Navigation-Label,
                > .Page_Navigation-Caret {
                    display: table-cell;
                    vertical-align: middle;
                }

                > .Page_Navigation-Icon {
                    text-align: center;
                    font-size: 2em;
                    width: 54px;

                    > .fa {
                        top: -2px;
                        position: relative;
                    }
                }

                > .Page_Navigation-Label {
                    font-weight: bold;
                    width: auto;
                }

                > .Page_Navigation-Caret {
                    width: 20px;
                    padding-right: 5px;
                    text-align: right;
                }

                &:visited {
                    color: $vbst-nav-text-color;
                }

                &:active,
                &:hover {
                    color: $vbst-nav-hover-text-color;
                    background: $vbst-nav-hover-bgcolor;

                    > .Page_Navigation-Icon {
                        color: $vbst-nav-hover-text-color;
                    }
                }
            }

            &.Page_Navigation--Hovered > a {
                color: $vbst-nav-hover-text-color;
                background: $vbst-nav-hover-bgcolor;

                > .Page_Navigation-Icon {
                    color: $vbst-nav-hover-text-color;
                }
            }
        }
    }

    &-Submenu {
        margin: 0;
        padding: 0;
        list-style: none;
        width: $vbst-nav-submenu-width;
        position: absolute;
        left: 270px;
        margin-top: -40px;
        background: $vbst-nav-hover-bgcolor;
        box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.2);
        display: none;

        > li {
            margin-top: 0;
            margin-bottom: 0;

            > a {
                color: $vbst-nav-hover-text-color;
                padding: 10px 20px;

                &:visited {
                    color: $vbst-nav-hover-text-color;
                }

                &:active,
                &:hover {
                    color: $vbst-nav-hover-bgcolor;
                    background: $vbst-nav-submenu-hover-bgcolor;
                }
            }
        }

        &--Opened {
            display: block;
        }
    }

    &-Icon {
        color: $vbst-nav-text-color;
    }
}

section.main-content {
    position: relative;
    padding: 0;
    vertical-align: top;

    @media (min-width: $screen-sm-min) {
        display: table-cell;
        // display: table;
    }

    > .breadcrumb-nav {
        position: relative;
        padding: 0 10px;
        margin: 0;
        height: 40px;
        background-color: #f5f5f5;
        border-top: 1px solid #ececec;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .05);
        padding-left: 3rem;

        @media (min-width: $screen-sm-min) {
            height: 60px;
            padding: 0 15px 0 calc(80px + 15px);
        }

        .Counselling__Print__Page & {
            display: none;
        }

        .breadcrumb-wrapper {
            display: inline-block;
        }

        h1 {
            display: inline-block;
            margin: 0;
            font-size: 17px;
            line-height: 40px;

            @media (min-width: $screen-sm-min) {
                font-size: 23px;
                line-height: 60px;
            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                font-size: 20px;
            }
        }

        .breadcrumb {
            padding: 0;
            margin: 0;
            background-color: inherit;
            position: absolute;
            bottom: -30px;
            z-index: 1000;
        }

        .breadcrumb > li + li:before {
            content: '> '
        }

        .navbar {
            position: absolute;
            display: inline-block;
            margin: 0;
            padding: 0;
            top: 0;
            right: 20px;
            margin-left: 25px;
            border: none;
            border-radius: 0;
            background-color: transparent;
            z-index: 1;

            @media (min-width: $screen-sm-min) {
                top: 12px;
                right: initial;
            }

            .navbar-header {
                button {
                    margin: 3px 0 0 0;
                    padding: 5px;
                }
            }

            .navbar-collapse {
                margin: 0;
                padding: 0;
                background-color: #fff;
                border: none;

                @media (min-width: $screen-sm-min) {
                    background-color: transparent;
                }
            }

            .navbar-nav {
                margin: 0;
                padding: 0;

                li {
                    &.active {
                        a,
                        a:hover,
                        a:focus {
                            color: $brand-primary;
                            font-weight: bold;
                            border-color: transparent;
                            background-color: transparent;
                            box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);

                            @media (min-width: $screen-sm-min) {
                                border-color: #ececec;
                                background-color: #fff;
                            }
                        }
                    }

                    a {
                        color: $vbst-text-color;
                        font-weight: bold;
                        margin: 0;
                        padding: 15px 40px;
                        border: 1px solid transparent;

                        @media (min-width: $screen-sm-min) {
                            margin: 0 2px 0 0;
                            padding: 13px 20px;
                        }

                        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                            padding-left: 10px;
                            padding-right: 10px;
                            font-size: 12px;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            color: $brand-primary;
                            font-weight: bold;
                            border-color: transparent;
                            background-color: transparent;
                            box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);

                            @media (min-width: $screen-sm-min) {
                                border-color: #ececec;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    > .content {
        padding: 20px 15px 100px 65px;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            padding: 20px 30px 100px 80px;
        }

        .Counselling__Print__Page & {
            padding: 0;
        }
    }
}

.Vbst {
    &_Intro {
        text-align: center;
    }

    &_Logo {
        display: block;
        margin: $vbst-logo-margin-top auto $vbst-logo-margin-bottom;
        width: $vbst-logo-width;
        height: $vbst-logo-height;
        background-image: url($vbst-logo-src);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $vbst-logo-width $vbst-logo-height;

        @media #{$retina} {
            background-image: url(retinize($vbst-logo-src));
        }
    }

    &_Headline {
        font-size: 1.5em;
        color: $vbst-intro-headline-color;
        font-weight: bold;
    }

    &_Container {
        &--simple {
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;

            @media (min-width: $screen-sm-min) {
                display: table;
            }

            h3.Container__Headline {
                margin-top: 10px;
                margin-bottom: 30px;
            }

            .Container__Explanation {
                font-size: 1.1em;
            }
        }

        &-Panel {
            position: absolute;
            top: 40px;
            width: 100%;
            padding: 0 15px;

            @media (min-width: $screen-sm-min) {
                left: 50%;
                width: 400px;
                margin-left: -200px;
            }

            &-Body {
                margin-bottom: 20px;
                background-color: #fff;
                box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
                overflow: hidden;

                > div:not(.Vbst_Container-Panel-Header) {
                    padding: 20px 30px;
                }
            }

            &-Content {
                h4 {
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: 600;
                }

                p {
                    margin: -5px 0 20px;
                    font-size: 12px;
                }

                .form-group {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .form-control {
                    padding: 10px 8px;
                    height: auto;

                    &:focus {
                        border-color: $brand-primary;
                        box-shadow: 0 1px 1px rgba(0, 0, 0, .01) inset;
                    }
                }

                .alert {
                    ul {
                        padding-left: 30px;
                    }

                    p {
                        margin: 0 0 5px;
                    }
                }
            }

            &-Copyright {
                text-align: center;
                color: darken(#ededed, 25%);
                font-size: 12px;
                font-weight: 300;
            }
        }

        &--medium &-Panel {
            @media (min-width: $screen-sm-min) {
                width: 1024px !important;
                margin-left: -512px;
            }

            .alert {
                font-size: 1.2em;

                &:first-child {
                    margin-top: 20px;
                }
            }

            .panel-body {
                padding: 0;
            }
        }
    }
}

.panel.panel-tabs .panel-heading {
    padding-top: 9px;
    padding-bottom: 0;

    .nav-tabs {
        border-bottom: 0;

        > li > a {
            color: #fff;
        }

        > li > a:hover,
        > li > a:focus {
            color: #111;
        }

        > li.active > a,
        > li.active > a:hover,
        > li.active > a:focus {
            background-color: #fff;
            border-color: #fff;
            color: #111;
        }
    }
}

.Header__Welcome {
    position: relative;
    float: left;
    height: 80px;
    text-align: center;

    @media (min-width: $screen-md-min) {
        width: $vbst-head-greeting-width;
    }

    &__Inner {
        padding-left: 25px;
        height: 100%;

        @media (min-width: $screen-md-min) {
            display: inline-block;
            padding-left: $vbst-head-ucp-width / 2;
            height: 80px;
            width: 100%;
        }

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &__Logo {
        float: left;
        width: 160px;
        height: 80px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 75px;
    }

    &__Greeting {
        float: left;
        font-size: 1.4em;
        margin-top: 10px;

        @media (min-width: $screen-md-min) {
            float: none;
        }
    }

    &__Clinic {
        &__Select {
            position: relative;
            display: inline-block;

            &:after {
                position: absolute;
                top: 10px;
                right: 8px;
                font-family: "Font Awesome 5 Pro";
                content: "\f150";
                color: #ccc;
                z-index: -1;
            }

            &:hover,
            &:focus,
            &:active {
                &:after {
                    color: #000;
                }
            }

            > select {
                background: transparent;
                border: transparent;
                margin-top: 7px;
                padding: 5px 30px 5px 10px;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
                border-radius: 5px;
                font-size: 0.85em;
                -webkit-appearance: none;
            }
        }
    }
}

.user-nav {
    text-align: right;

    > li {
        float: right;
        // display: inline-block;
    }

    @media (min-width: $screen-md-min) {
        width: $vbst-head-ucp-width;
    }

    .fa {
        color: #6f6f6f;
    }

    .dropdown-menu {
        top: 85%;
        right: -30px;
        border-top-left-radius: $border-radius-base !important;
        border-top-right-radius: $border-radius-base !important;
        border-top: 1px solid rgba(0, 0, 0, 0.15) !important;

        a > i.fa {
            margin-right: 10px;
        }
    }

    > li {
        &:last-child .dropdown-menu {
            right: 0;
        }

        > a {
            position: relative;

            > i.fa {
                font-size: 1.4em;
            }

            > .badge {
                position: absolute;
                top: 22%;
                right: 5px;
                background: #e6181c;
                padding-top: 4px;
                padding-bottom: 4px;
                border-radius: 50px;
            }
        }
    }
}

.Navbar__Dropdown {
    &__Label {
        color: $brand-primary;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    > .dropdown-menu {
        padding-top: 15px;
        padding-bottom: 15px;
        min-width: 220px;

        > li {
            padding-left: 15px;
            padding-right: 15px;
        }

        > li.divider {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__List {
        .text-muted {
            font-size: 0.9em;
        }

        > ul,
        > div > ul,
        li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        > ul,
        > div > ul {
            margin-top: 5px;

            > li {
                padding: 5px;
                // margin-top: 5px;
                // margin-bottom: 5px;

                &:hover {
                    background-color: #eee;
                    cursor: pointer;
                }
            }
        }

        &__Timestamp {
            display: block;
            font-size: 0.8em;
            color: #6a6a6a;
        }
    }

    &__Listing {

        &__Item {
            position: relative;

            .fa {
                font-size: 1.6em;
            }

            &--First-Counselling {
                .fa {
                    color: $counselling-message-alternate-accent-color;
                }
            }

            &--Counselling {
                .fa {
                    color: $counselling-message-accent-color;
                }
            }

            &--Messages {
                .fa {
                    color: $brand-primary;
                }
            }

            .badge {
                position: absolute;
                top: -8px;
                left: 17px;
                background: #e6181c;
                padding-top: 4px;
                padding-bottom: 4px;
                border-radius: 50%;
            }
        }
    }

    &__Search {

    }

    &--Search {
        > .dropdown-menu {
            min-width: 300px;
            padding-top: 0;

            > li:first-child {
                padding-top: 10px;
            }

            > li:last-child {
                padding-left: 0;
                padding-right: 0;
            }

            a > i.fa {
                color: inherit;
            }
        }

        .nav-tabs > li > a {
            border-radius: 0;
        }

        .nav-tabs > li:first-child > a {
            border-top-left-radius: $border-radius-base;
        }

        .nav-tabs > li:last-child > a {
            border-top-right-radius: $border-radius-base;
        }

        .tab-pane {
            padding: 10px;
        }
    }

    &--Messages {

    }

    &--Notifications {
        > .dropdown-menu {
            min-width: 400px;
            max-height: 50vh;
            overflow: hidden;
        }

        .text-muted {
            border-bottom: 1px solid #777;
            display: block;
            padding-bottom: 3px;
        }
    }
}

.Navbar__Search {
    &__Item {
        border-bottom: 1px dashed #ddd;

        > a {
            display: block;
            padding: 10px;

            &.Selected {
                color: #fff;
                background: $brand-primary;
            }
        }
    }

    &__Controls {
        text-align: center;
        margin-top: 15px;

        a {
            font-size: 1.4em;
            color: #6f6f6f;

            &:hover {
                color: $link-color;
            }

            &[disabled] {
                color: rgba(#6f6f6f, 0.5);
                cursor: not-allowed;
            }
        }
    }

    &__Information {
        padding-top: 10px;

        table {
            width: 100%;

            tbody td {
                padding: 2px 0;
            }
        }
    }
}

.dropdown-menu > li > a.Navbar__Dropdown__Listing__Item,
header.main-header .navbar-collapse .navbar-nav .open .dropdown-menu > li > a.Navbar__Dropdown__Listing__Item {
    padding-left: 0;
    padding-right: 0;
    color: $brand-primary;

    &:hover,
    &:focus {
        background: transparent;
        color: #960011;
    }
}

.user-nav .dropdown-menu a.Navbar__Dropdown__Listing__Item > i.fa {
    margin-right: 13px;
}

//--------------------------------------------------

.Mobilewarn {
    @media (min-width: $screen-lg-min) {
        display: none;
    }
}

.user-form {
    .form-group {
        position: relative;

        .form-control-valid-indicator {
            color: #00897b;
            position: absolute;
            right: 5px;
            top: 25px;
            display: block;
            font-size: 1.5em;
        }
    }
}

.mt-10 {
    margin-top: 10px;
}

.well-authenticated-accounts {
    position: relative;

    td {
        padding: 5px 0;
    }

    .remove-account {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #e6181c;
        padding: 0px 7px;
        height: 26px;
        width: 26px;
        border-radius: 3px;
        color: #fff;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: darken(#e6181c, 10);
        }
    }
}
