.First-Counselling {
    color: $counselling-message-alternate-accent-color;
}

.Counselling {
    color: $counselling-message-accent-color;
}

.Counselling_Messages {
    &__Info {
        text-align: center;
        margin: 20px 0;
    }

    .Counselling_Message {
        border: 1px solid $counselling-message-border-color;
        position: relative;

        &--is-first {
            // border-left: 4px solid $counselling-message-alternate-accent-color;

            .Counselling_Message-Label {
                background: $counselling-label-alternate-bgcolor;
            }
        }

        &-Content {
            padding: 20px;

            &:before,
            &:after {
                content: "";
                display: table;
            }

            &:after {
                clear: both;
            }
        }

        &-Body {
            float: left;
            width: 70%;
            color: $counselling-message-text-color;
            padding-right: 10px;
            @media (max-width: 309.98px) {
                width: 60%;
            }
        }

        &__Preview {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.25s ease-in-out;
            margin: 10px 0;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        }

        &--Opened .Counselling_Message__Preview {
            max-height: 500px;
        }

        &-Label {
            display: inline-block;
            background: $counselling-label-bgcolor;
            border-radius: $counselling-label-border-radius;
            color: $counselling-label-color;
            font-size: 0.857em;
            padding: 3px 10px;

            &--Alert {
                background: #FFAB40;
            }

            &--Answered {
                background: #2b9a70;
            }
        }

        &-Information {
            margin: 0px;
            margin-bottom: 10px;
            font-size: 1.071em;
        }

        &-User {
            font-weight: bold;
        }

        &-Title {
            color: $counselling-message-title-color;
            font-weight: bold;

            &:visited {
                color: $counselling-message-visited-title-color;
            }

            &:hover,
            &:active {
                color: $counselling-message-hover-title-color;
            }
        }

        &-Timestamp {
            font-size: 0.857em;
        }

        &-Controls {
            float: right;
            width: 30%;
            padding-right: 0;

            > ul {
                margin: 0;
                padding: 0;
                list-style: none;

                > li {
                    margin: 0;
                    padding: 0;

                    &:first-child > a {
                        border-top-left-radius: $counselling-control-border-radius;
                        border-top-right-radius: $counselling-control-border-radius;
                    }

                    &:last-child > a {
                        border-bottom-left-radius: $counselling-control-border-radius;
                        border-bottom-right-radius: $counselling-control-border-radius;
                        @media (max-width: 359.98px) {
                            font-size: 12px;
                            padding: 5px;
                        }
                    }
                }
            }
            @media (max-width: 309.98px) {
                width: 40%;
            }
        }

        &-Control {
            background: $counselling-control-bgcolor;
            border: 1px solid $counselling-message-border-color;
            display: block;
            width: 100%;
            color: $counselling-control-text-color;
            text-align: center;
            padding: 10px;

            &:active,
            &:hover {
                background: $counselling-control-hover-bgcolor;
            }

            &--is-important {
                background: $counselling-control-important-bgcolor;
                border-color: $counselling-control-important-bgcolor;
                color: $counselling-control-important-text-color;

                &:hover,
                &:active {
                    background: $counselling-control-important-hover-bgcolor;
                }
            }
        }

        &-Footer {
            width: 100%;
            position: absolute;
            bottom: -1px;
            text-align: center;
        }

        &-Hide {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
        }

        &-Readmore {
            padding: 0 20px;
        }

        &-Readmore,
        &-Hide {
            background: $counselling-control-bgcolor;
            border: 1px solid $counselling-message-border-color;
            color: $counselling-control-text-color;
            font-size: 0.857em;

            &:visited {
                color: $counselling-control-text-color;
            }

            &:active,
            &:hover {
                background: $counselling-control-hover-bgcolor;
            }
        }
    }

    &-All {
        display: block;
        text-align: center;
        font-size: 1.071em;
        color: $counselling-message-title-color;
        padding: 20px;
        font-weight: bold;

        &:visited {
            color: $counselling-message-title-color;
        }

        &:active,
        &:hover {
            color: $counselling-message-hover-title-color;
        }
    }
}

.Contingents-Quickview {
    &-List {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-Listitem {
        margin: 0;
        padding: 0;
        background: $contingents-quick-bgcolor;
        color: $contingents-quick-text-color;
        padding: 5px 10px;
        border-bottom: 1px solid $contingents-quick-border-color;

        &:hover {
            background: $contingents-quick-hover-bgcolor;

            .Contingents-Quickview-Date {
                font-weight: bold;
            }
        }

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-Value {
        display: inline-block;
        width: 20px;
        text-align: center;
    }

    &-Date {
        width: 50%;
        float: left;
        font-size: 0.750em;
        line-height: 3.3em;
    }

    &-Controls {
        width: 50%;
        float: right;
        text-align: right;
    }

    &-Control {
        border: 0;
        height: 34px;
        width: 34px;
        border-radius: 17px;
        font-size: 1.286em;
        background: $contingents-quick-control-bgcolor;
        color: $contingents-quick-control-text-color;

        &:focus,
        &:active {
            outline: none;
        }

        &:hover {
            background: $contingents-quick-control-hover-bgcolor;
        }

        &--decreases-value {
            background: $contingents-quick-control-decrease-bgcolor;
            color: $contingents-quick-control-decrease-text-color;

            &:hover {
                background: $contingents-quick-control-decrease-hover-bgcolor;
            }
        }

        &--increases-value {
            background: $contingents-quick-control-increase-bgcolor;
            color: $contingents-quick-control-increase-text-color;

            &:hover {
                background: $contingents-quick-control-increase-hover-bgcolor;
            }
        }
    }

    &-Value {
        width: 34px;
        display: inline-block;
        font-size: 1em;
        text-align: center;

        &:focus,
        &:active {
            outline: none;
        }
    }

    &-All {
        display: block;
        text-align: center;
        font-size: 1.071em;
        color: $counselling-message-title-color;
        padding: 20px;
        font-weight: bold;

        &:visited {
            color: $counselling-message-title-color;
        }

        &:active,
        &:hover {
            color: $counselling-message-hover-title-color;
        }
    }
}

.redactor-toolbar li a[disabled]:hover {
    background-color: transparent;
}

.redactor-toolbar li a[disabled]:hover i:before {
    color: #333;
}

.Counselling__Message__Editor {
    #message {
        visibility: hidden;
    }

    .re-icon[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.Counselling__Reply {
    background: $brand-primary;

    .Counselling__Print__Page & {
        background: #fff;
        border-top: 1px dashed darken(#f5f5f5, 20);
        margin-bottom: 10px;
    }

    &__Head,
    &__Body {
        margin-left: 10px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

        .Counselling__Print__Page & {
            margin-left: 0;
        }
    }

    &--Client {
        .Counselling__Reply__Head,
        .Counselling__Reply__Body {
            margin-left: 0;
            border-left: 10px solid transparent;
        }

        .Counselling__Reply__Head {
            background: #f5f5f5;
        }
    }

    &__Icon {
        float: left;

        > .fa {
            font-size: 2em;
            margin: 7px 10px 0 0;
        }
    }

    &__Head {
        background: lighten(saturate(adjust-hue($brand-primary, -1.0572), 0.7158), 43.7255);
        padding: 20px 30px;
        position: relative;

        .Counselling__Print__Page & {
            padding: 10px;
            background: #fff;
        }
    }

    &__Body {
        background: #fff;
        padding: 30px;

        .Counselling__Print__Page & {
            padding: 10px;
        }
    }

    &__Subject {
        font-weight: bold;

        .Counselling__Print__Page & {
            color: #333;
        }

        .fa {
            color: #111;
        }
    }

    &__Timestamp {
        font-size: 0.9em;
    }

    &__User {
        font-size: 0.9em;

        &:before {
            content: "";
            margin: 0 15px;
        }
    }

    &__Attachments {
        position: absolute;
        right: 22px;
        top: 22px;

        .dropdown-menu {
            left: auto;
            right: 0;
        }

        .dropdown-toggle,
        .dropdown-toggle:hover,
        .dropdown-toggle:focus,
        .dropdown-toggle.focus {
            background-color: transparent !important;
            border-color: transparent !important;
            outline: 0;
            box-shadow: none;
        }
    }

    &__Attachment {
        > a {
            display: flex !important;
            justify-content: space-between;
            line-height: 1.8em !important;

            .fa {
                line-height: 1.8em;
                margin-right: 5px;
            }

            .name {
                font-weight: bold;
                margin-right: 20px;
            }

            .size {
                font-style: italic;
                font-size: 0.9em;
            }
        }
    }
}

.Counselling__Invitations {
    padding: 10px;
}

.Counselling__Invitation {
    border-bottom: 1px solid #e4e4e4;
    padding: 20px;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    &__Body {
        float: left;
        width: 80%;
        color: #3f373f;
        padding-right: 20px;
    }

    &__Controls {
        float: right;
        width: 20%;

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                margin: 0;
                padding: 0;

                &:first-child > a {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }

                &:last-child > a {
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }
    }

    &__Control {
        background: #eee;
        border: 1px solid #e4e4e4;
        display: block;
        width: 100%;
        color: #3f373f;
        text-align: center;
        padding: 10px;

        &:active,
        &:hover {
            background: $counselling-control-hover-bgcolor;
        }

        &--Important {
            background: #3983c3;
            border-color: #3983c3;
            color: #fff;

            &:hover,
            &:active {
                color: #fff;
                background: $counselling-control-important-hover-bgcolor;
            }
        }
    }

    &__Properties {
        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &__Issuer,
    &__Expiration {
        width: 50%;
        font-size: 0.857em;
    }

    &__Issuer {
        float: left;

        &__Name,
        &__Timestamp {
            font-weight: bold;
        }
    }

    &__Expiration {
        float: right;
        text-align: right;
    }

    &__Counselling {
        font-size: 1.071em;
        margin: 10px 0;

        &:hover {
            cursor: pointer;
        }

        &__Client,
        &__Subject {
            font-weight: bold;
        }

        &__Subject {
            color: #337ab7;
        }
    }
}

.Counselling__Invitations .Counselling__Invitation:last-child {
    border-bottom: 0;
}

.Counselling__Participations {
    margin-top: 20px;

    .panel-heading {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.Counselling__Archive__Prove {
    &:before {
        font-family: "Font Awesome 5 Pro"; 
        font-weight: normal;
        font-style: normal;
        display: inline-block;
        text-decoration: inherit;
        content: "\f006";
    }

    &:hover::before,
    &--Proved:before {
        content: "\f005";
    }

    &--Proved:hover::before {
        content: "\f006";
    }
}

.Counselling__Message__Notice {
    background: darken($brand-primary, 20);
    color: #fff;
    padding: 5px 20px;
    font-size: 0.857em;

    &--attachments {
        .attachments-headline {
            display: block;
            font-size: 1.1em;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        ul {
            li {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.Counselling__Message__Review {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: padding 0.25s ease-in-out, max-height 0.25s ease-in-out;

    &--Visible {
        padding-top: 20px;
        padding-bottom: 20px;
        max-height: 2000px;
    }

    del.diffmod,
    del.diffdel {
        background: #ff8a80;
    }

    ins.diffmod,
    ins.diffins {
        background: #b9f6ca;
    }

    [data-comment] {
        position: relative;

        &:before {
            font-family: "Font Awesome 5 Pro"; 
            content: "\f0e5";
            color: $brand-primary;
            transform: scale(-1, 1);
            display: inline-block;
            margin-right: 3px;
            position: relative;
            top: -5px;
        }

        &:after {
            position: absolute;
            transform: translate3d(0, 0, 0);
            visibility: hidden;
            opacity: 0;
            z-index: 1000000;
            pointer-events: none;
            transition: 0.2s ease;
            transition-delay: 0ms;
            content: attr(data-comment);
            padding: 8px 10px;
            white-space: normal;
            line-height: 1.4em;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-left: 6px;
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
            background-color: #82b1ff;
            color: #000;
            border-radius: 4px;
            transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
            width: 200px;
        }

        &:hover:after {
            visibility: visible;
            opacity: 1;
            transition-delay: 100ms;
            transform: translateX(-50%) translateY(3px);
        }
    }

    [data-comment='']:before,
    [data-comment='']:after {
        display: none !important;
    }

    &--Has-Comments {
        overflow: visible;
    }
}
