/**
 * Bootstrap overrides
 * --------------------------------------------------
 */
$zindex-modal-background:  5001;
$zindex-modal:             5002;

/*
 * Scaffolding
 * --------------------
 */
$body-bg:                   #ededed;
$text-color:                #333333;

/*
 * Fonts
 * --------------------
 */
$font-family-sans-serif:    "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

/*
 * Navbar
 * --------------------
 */
$navbar-height:             80px;

/*
 * Borders
 * --------------------
 */
$border-radius-base:        6px;

/*
 * Panels
 * --------------------
 */
$panel-heading-padding:     15px;
$panel-default-heading-bg:  #6b6b6b;
$panel-default-text:        #fff;

/*
 * Branding
 * --------------------
 */
$brand-primary:             #6b6b6b;
$brand-info:                #64b5f6;
$brand-success:             #5cb85c;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;

/*
 * Links
 * --------------------
 */
$link-color:                #337ab7;

/*
 * Buttons
 * --------------------
 */
$btn-default-color:              #333;
$btn-default-bg:                 #fff;
$btn-default-border:             #ccc;

$btn-primary-color:              #fff;
$btn-primary-bg:                 #5F9CD0;
$btn-primary-border:             darken($btn-primary-bg, 5%);

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             darken($btn-success-bg, 5%);

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                darken($btn-info-bg, 5%);

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             darken($btn-warning-bg, 5%);

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              darken($btn-danger-bg, 5%);

/**
 * FontAwesome overrides
 * --------------------------------------------------
 */
$fa-font-path: '/fonts';


/**
 * VBSt specific variables
 * --------------------------------------------------
 */

$branding-color-primary:                            $brand-primary;

$vbst-logo-src:                                     "/img/template/login-logo.png";
$vbst-logo-width:                                   214px;
$vbst-logo-height:                                  97px;
$vbst-logo-margin-top:                              80px;
$vbst-logo-margin-bottom:                           20px;

$vbst-intro-headline-color:                         #4b444b;

$vbst-text-color:                                   #3f373f;

$vbst-nav-bgcolor:                                  linear-gradient(to bottom,  #2d7eae 0%, #01a25f 100%);
$vbst-nav-hover-bgcolor:                            #f2f2f2;
$vbst-nav-border-color:                             #ececec;
$vbst-nav-text-color:                               #fff;
$vbst-nav-hover-text-color:                         $vbst-text-color;
$vbst-nav-brand-logo-src:                           "/img/template/logo.png";
$vbst-nav-brand-logo-width:                         50px;
$vbst-nav-brand-logo-height:                        23px;
$vbst-nav-opened-width:                             270px;
$vbst-nav-submenu-width:                            240px;
$vbst-nav-submenu-hover-bgcolor:                    #4b444b;

$vbst-head-greeting-width:                          70%;
$vbst-head-ucp-width:                               30%;

$retina:                                            "only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)";

$sidebar-width:                                     55px;
$sidebar-background:                                #27343c;

$counselling-message-accent-color:                  #2b9a70;
$counselling-message-alternate-accent-color:        #8fb813;
$counselling-message-border-color:                  #ccc;
$counselling-message-title-color:                   $link-color;
$counselling-message-hover-title-color:             lighten($counselling-message-title-color, 5);
$counselling-message-text-color:                    $vbst-text-color;
$counselling-message-visited-title-color:           darken($counselling-message-title-color, 10);

$counselling-label-bgcolor:                         $counselling-message-accent-color;
$counselling-label-alternate-bgcolor:               #8fb813;
$counselling-label-border-radius:                   $border-radius-base;
$counselling-label-color:                           #fff;

$counselling-control-bgcolor:                       #eee;
$counselling-control-hover-bgcolor:                 darken($counselling-control-bgcolor, 10);
$counselling-control-important-bgcolor:             #5F9CD0;
$counselling-control-important-hover-bgcolor:       darken($counselling-control-important-bgcolor, 10);
$counselling-control-border-radius:                 $border-radius-base;
$counselling-control-text-color:                    $counselling-message-text-color;
$counselling-control-important-text-color:          #fff;

$contingents-quick-text-color:                      $vbst-text-color;
$contingents-quick-bgcolor:                         #fff;
$contingents-quick-hover-bgcolor:                   #f5f5f5;
$contingents-quick-border-color:                    #e4e4e4;

$contingents-quick-control-bgcolor:                 #eee;
$contingents-quick-control-hover-bgcolor:           darken($contingents-quick-control-bgcolor, 10);
$contingents-quick-control-text-color:              #000;
$contingents-quick-control-increase-bgcolor:        #95b93e;
$contingents-quick-control-increase-hover-bgcolor:  darken($contingents-quick-control-increase-bgcolor, 10);
$contingents-quick-control-increase-text-color:     #fff;
$contingents-quick-control-decrease-bgcolor:        #e10019;
$contingents-quick-control-decrease-hover-bgcolor:  darken($contingents-quick-control-decrease-bgcolor, 10);
$contingents-quick-control-decrease-text-color:     #fff;

$helpdesk-teaser-border-color:                      #e4e4e4;
$helpdesk-teaser-item-border-color:                 #333;
$helpdesk-teaser-text-color:                        $vbst-text-color;
$helpdesk-teaser-border-radius:                     $border-radius-base;

$helpdesk-index-book-color:                         #023b69;
$helpdesk-index-chapter-color:                      #0059df;
$helpdesk-index-page-color:                         #57aeff;

$news-list-border-color:                            #e4e4e4;
$news-list-even-bgcolor:                            #fff;
$news-list-odd-bgcolor:                             #f5f5f5;
$news-list-label-bgcolor:                           #3f373f;
$news-list-label-text-color:                        #fff;
$news-list-label-border-radius:                     $border-radius-base;

$datatables-border-color:                           #e2e2e2;
$datatables-row-bgcolor:                            #fff;
$datatables-row-alternate-bgcolor:                  #fcfcfc;
$datatables-row-hover-bgcolor:                      #f2f2f2;
$datatables-text-color:                             #333;
$datatables-text-alternate-color:                   lighten($datatables-text-color, 40);
$datatables-processing-color:                       #00a25e;

$contingents-head-bgcolor:                          $link-color;
$contingents-head-hover-bgcolor:                    #fff;
$contingents-head-text-color:                       #fff;
$contingents-head-hover-text-color:                 $link-color;
$contingents-border-color:                          #f3f3f3;
$contingents-row-bgcolor:                           #fcfcfc;
$contingents-row-alternate-bgcolor:                 #fff;

$client-file-toggle-width:                          50px;
$client-file-toggle-color:                          #fff;
$client-file-toggle-color-focus:                    #fff;
$client-file-toggle-color-hover:                    #5184af;
$client-file-toggle-border:                         1px solid #d2d2d2;
$client-file-toggle-background:                     #5F9CD0;
